import React from 'react'
import clsx from 'clsx'

import * as styles from '../styles/components/Statistics.module.scss'

import { SectionTitle } from './SectionTitle'

export const Statistics: React.FC = () => {
  return (
    <section id="numeros">
      <div className="container">
        <SectionTitle
          title="Nossa experiência"
          description="Esses são alguns números da Valore Brasil em gerenciamento de projetos"
        />
      </div>

      <div className="pt-3 pb-5">
        <div className="container">
          <div className="row justify-content-center">

            <div className={clsx([`col-lg-3`, styles.statisticsItem])}>
              <b>+10</b>
              <span>Anos de mercado</span>
            </div>

            <div className={clsx([`col-lg-3`, styles.statisticsItem])}>
              <b>+300</b>
              <span>Projetos concluídos</span>
            </div>

            <div className={clsx([`col-lg-3`, styles.statisticsItem])}>
              <b>+3.000</b>
              <span>Processos melhorados (BPM)</span>
            </div>

          </div>
        </div>
      </div>
    </section>
  )
}