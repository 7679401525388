import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker } from '@fortawesome/free-solid-svg-icons'
import clsx from 'clsx'

import * as styles from '../styles/components/Offices.module.scss'
import escritorioSp from '../images/escritorioSp.jpg'
import escritorioRibeirao from '../images/escritorioRibeirao.png'
import escritorioGoiania from '../images/escritorioGoiania.jpg'
import escritorioCampinas from '../images/escritorioCampinas.jpg'

import { Button } from './Button'
import { SectionTitle } from './SectionTitle'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

const offices = [
  {
    location: `São Paulo/SP`,
    address: `Rua Funchal 418 - Edifício E-Tower, 34º e 35º andares | Vila Olímpia`,
    link: `https://www.google.com/maps/place/Valore+consultoria+em+avalia%C3%A7%C3%A3o+de+empresas+(Valuation)+e+Processos+(BPM)./@-23.5937239,-46.6926648,17z/data=!3m1!4b1!4m5!3m4!1s0x94ce571ecd4a82ad:0x4c41f68ef194a1cc!8m2!3d-23.593636!4d-46.6905292`,
    image: escritorioSp
  },
  {
    location: `Ribeirão Preto/SP`,
    address: `Av. Presidente Vargas, 2121 - Salas 908 e 909 - Ed. Times Square Business`,
    link: `https://www.google.com/maps?q=edificio+times+square+ribeirao&rlz=1C1PRFI_enBR790BR790&um=1&ie=UTF-8&sa=X&ved=0ahUKEwjn3vrG1ODlAhWaEbkGHcAcBt0Q_AUIEigB`,
    image: escritorioRibeirao
  },
  {
    location: `Goiânia/SP`,
    address: `Ed. Flamboyant Park Business 26º Andar - Sala 2610`,
    link: `https://www.google.com/maps/place/Flamboyant+Park+Business/@-16.7067459,-49.2388658,17z/data=!3m1!4b1!4m5!3m4!1s0x935ef1acdd1b1829:0xc5aed1ac68302a95!8m2!3d-16.7067459!4d-49.2366771`,
    image: escritorioGoiania
  },
  {
    location: `Campinas/SP`,
    address: `Av. José Bonifácio Coutinho Nogueira, 150 Térreo Cj. 2 | Galleria Plaza`,
    link: `https://www.google.com/maps/place/Av.+Dr.+Jos%C3%A9+Bonif%C3%A1cio+Coutinho+Nogueira,+150+-+Res.+Vila+Verde,+Campinas+-+SP,+13091-611/@-22.8658769,-47.0251126,17z/data=!3m1!4b1!4m5!3m4!1s0x94c8c59c5ff7aff1:0x92ea44bd22114be0!8m2!3d-22.8658769!4d-47.0229239`,
    image: escritorioCampinas
  }
]

const OfficeList: React.FC = () => (
  <div className="row">
    {offices.map((office, index) => (
      <div
        key={index}
        className="col-lg-6 col-xl-3 mb-5 mb-xl-0"
      >
        <a
          href={office.link}
          target="_blank"
          rel="noopener"
        >
          <div className={styles.officesItem}>
            <div
              className={styles.officeImage}
              style={{ backgroundImage: `url(${office.image})` }}
            />
            <div className={clsx([`p-3`, styles.content])}>
              <b>{office.location}</b>
              <span>{office.address}</span>
              <Button>
                <FontAwesomeIcon
                  className="mr-2"
                  icon={faMapMarker}
                />
                Ver no mapa
              </Button>
            </div>
          </div>
        </a>
      </div>
    ))}
  </div>
)

export const Offices: React.FC = () => {

  const onContactClick = () => {
    const elem: HTMLInputElement = document.querySelector(`form#contato input[name="name"]`)!
    elem.focus()
  }

  return (
    <section id="escritorios">
      <div className="container">
        <SectionTitle
          title="Nossos escritórios"
          description="Sempre um escritório perto de você para melhor lhe atender"
        />

        <OfficeList />

        <div className="py-5">
          <div className={clsx([`p-5`, styles.moreInfoCard])}>
            <div className="row align-items-center">
              <div className="col-lg mb-4 mb-lg-0">
                <h1 className="mb-3 mb-lg-0">
                  Não achou um escritório proximo?
                </h1>
                <p>
                  Sem problemas, a Valore Brasil atende em <b>todo o território nacional!</b>
                </p>
              </div>
              <div className="col-lg-auto">
                <AnchorLink
                  to="/#contato"
                  onAnchorLinkClick={onContactClick}
                >
                  <Button>
                    Fale com a gente
                  </Button>
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}