import clsx from 'clsx'
import React from 'react'

import * as styles from '../styles/components/Button.module.scss'

type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>

export const Button: React.FC<ButtonProps> = (props) => {
  return (
    <button className={clsx([
      styles.button,
      props.className
    ])}>
      {props.children}
    </button>
  )
}