import clsx from 'clsx'
import React from 'react'

import * as styles from '../styles/components/Divider.module.scss'

interface DividerProps {
  className?: string
}

export const Divider: React.FC<DividerProps> = (props) => {
  return (
    <hr
      className={clsx([
        styles.divider,
        props.className
      ])}
    />
  )
}