import React from 'react'
import clsx from 'clsx'

import '../styles/global.scss'
import * as styles from '../styles/pages/index.module.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCogs,
  faProjectDiagram,
  faLayerGroup,
  faServer,
} from '@fortawesome/free-solid-svg-icons'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import { SEO } from '../components/SEO'
import { Divider } from '../components/Divider'
import { Header } from '../components/Header'
import { Slider } from '../components/Slider'
import { SectionTitle } from '../components/SectionTitle'
import { AboutUs } from '../components/AboutUs'
import { Statistics } from '../components/Statistics'
import { Offices } from '../components/Offices'
import { Footer } from '../components/Footer'
import { Button } from '../components/Button'
import { SuccessCases } from '../components/SuccessCases'

const Home: React.FC = () => {
  return (
    <main>
      <SEO title="Gestāo por processos de negócio BPM - Valore Brasil" />
      <Header />
      <Slider />

      <section id="servicos">
        <div className="pt-3 pb-5">
          <div className="container">
            <SectionTitle
              title="Nosso Propósito"
              description="
              Buscar a melhor solução para seu processo, seja apenas uma padronização
              ou ainda mudanças mais sofisticadas como RPA e IA."
            />
          </div>
          <div className={clsx([`container`, styles.containerLarge])}>
            <div className="row mt-3">
              <div
                className={clsx([`col-lg-3 mb-5 mb-lg-0`, styles.stepsItem])}
              >
                <div className={styles.stepCircle}>
                  <FontAwesomeIcon icon={faCogs} />
                </div>
                <b>Mapeamento e Melhoria de Processos</b>
                <p>
                  Atuamos com base nas boas práticas de mercado, começando pelo
                  mapeamento de processo AS IS, sugerimos melhorias, desenhamos
                  o processo TO BE e implantamos as mudanças.
                </p>
                <a
                  href="https://blog.valoremelhoria.com.br/blog/"
                  target="_blank"
                  rel="noopener"
                >
                  Saiba mais em nosso blog
                </a>
              </div>

              <div
                className={clsx([`col-lg-3 mb-5 mb-lg-0`, styles.stepsItem])}
              >
                <div className={styles.stepCircle}>
                  <FontAwesomeIcon icon={faProjectDiagram} />
                </div>
                <b>Escritório de Processos</b>
                <p>
                  Fazemos a implantação do Escritório de Processos, responsável
                  por apoiar toda a organização com metodologias, técnicas,
                  ferramentas, e treinamento em gestão por processos, desde o
                  nível operacional ao estratégico.
                </p>
                <a
                  href="https://blog.valoremelhoria.com.br/blog/"
                  target="_blank"
                  rel="noopener"
                >
                  Saiba mais em nosso blog
                </a>
              </div>

              <div
                className={clsx([`col-lg-3 mb-5 mb-lg-0`, styles.stepsItem])}
              >
                <div className={styles.stepCircle}>
                  <FontAwesomeIcon icon={faLayerGroup} />
                </div>
                <b>Treinamento BPM</b>
                <p>
                  Conceitos BBPM baseados no CBOK 4.0, boas práticas e
                  frameworks de processos. Entenda o modelo organizacional para
                  identificar, desenhar, executar, documentar, medir, monitorar,
                  controlar e melhorar processos de negócio.
                </p>
                <a
                  href="https://blog.valoremelhoria.com.br/2021-07-22-forma%C3%A7%C3%A3o-de-analista-de-processos-gerenciamento-de-processos-de-neg%C3%B3cios-bpm/"
                  target="_blank"
                  rel="noopener"
                >
                  Veja aqui a ementa
                </a>
              </div>
              <div
                className={clsx([`col-lg-3 mb-2 mb-lg-0`, styles.stepsItem])}
              >
                <div className={styles.stepCircle}>
                  <FontAwesomeIcon icon={faServer} />
                </div>
                <b>Aderência de Sistemas</b>
                <p>
                  Apoiamos na escolha do melhor sistema de gestão (ERP ou CRM)
                  para o seu negócio. Avaliamos a aderência do sistema aos
                  processos, relação custo-benefício, provas de conceito. Seja
                  assertivo na tomada de decisão para migração ou aquisição de
                  novo sistema.
                </p>
                <a
                  href="https://blog.valoremelhoria.com.br/2021-07-27-como-fazemos-ader%C3%AAncia-de-sistema-atrav%C3%A9s-do-mapeamento-dos-processos/"
                  target="_blank"
                  rel="noopener"
                >
                  Saiba mais em nosso blog{' '}
                </a>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row mt-5 align-items-center justify-content-center">
              <a
                href="https://valoremelhoria.com.br/#block8421"
                target="_blank"
                rel="noopener"
              >
                <Button>Conheça todos os nossos serviços</Button>
              </a>
            </div>
          </div>
        </div>

        <div className="container">
          <Divider />
        </div>

        <div className="py-5">
          <div className={clsx([`container`, styles.containerLarge])}>
            <div className="row align-items-center">
              <div className="col-lg-4 mb-3 mb-lg-0 pr-4">
                <h2 className="mb-3" style={{ color: '#1fa9e2' }}>
                  Metodologia Utilizada
                </h2>
                <p className="mb-3">
                  Conheça os 04 principais passos de nossa metodologia para
                  implementar a mudança na sua organização.
                </p>

                <AnchorLink to={'/#contato'} className="hidden-mobile">
                  <Button>Dúvidas? Fale com a gente</Button>
                </AnchorLink>
              </div>
              <div className={clsx(['col-lg-8 pl-4', styles.boxMethodology])}>
                <ul className={styles.methodology}>
                  <li>
                    <span>
                      <small>#</small>1
                    </span>
                    <div>
                      <h3>Planejamento</h3>
                      <p>
                        A fase do planejamento é um processo comercial. Neste
                        momento ainda não houve a contratação do produto. O
                        objetivo desta etapa é alinhar todas as expectativas do
                        projeto e os objetivos junto à contratante.
                        <AnchorLink to={'/#contato'}>
                          {' '}
                          Agende aqui essa etapa sem compromisso.
                        </AnchorLink>
                      </p>
                    </div>
                  </li>
                  <li>
                    <span>
                      <small>#</small>2
                    </span>
                    <div>
                      <h3>Mapeamento</h3>
                      <p>
                        A etapa do mapeamento do processo é o momento em que
                        identificamos as dores dos colaboradores da empresa
                        cliente, quanto às atividades do dia a dia que levaram o
                        processo a chegar à estrutura atual.
                      </p>
                    </div>
                  </li>
                  <li>
                    <span>
                      <small>#</small>3
                    </span>
                    <div>
                      <h3>Análise e Melhoria</h3>
                      <p>
                        Nesta etapa iremos analisar os processos diante de suas
                        variáveis: pessoas, riscos e controles internos, lógica
                        do fluxo do processo, sistemas de informação e
                        indicadores de desempenho para sugerir melhorias. Será
                        criada a nova versão de processo.
                      </p>
                    </div>
                  </li>
                  <li>
                    <span>
                      <small>#</small>4
                    </span>
                    <div>
                      <h3>Implementação</h3>
                      <p>
                        Muito importante para a melhoria não ficar apenas na
                        teoria. A Valore utiliza as técnicas de GMO do instituto
                        HCMI para engajar e "puxar" as mudanças para a empresa
                        realmente implantar o que foi aprovado. Com reuniões
                        periódicas unem-se os agentes das mudanças (comitê) para
                        reportarem as necessidades ou as dificuldades da
                        implantação.
                      </p>
                    </div>
                  </li>
                  <li className="pt-4 justify-content-center hidden-mobile-up">
                    <AnchorLink to={'/#contato'}>
                      <Button>Dúvidas? Fale com a gente</Button>
                    </AnchorLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <SuccessCases />
      <AboutUs />
      <Statistics />
      <div className="container">
        <Divider />
      </div>

      <Offices />
      <Footer />
    </main>
  )
}

export default Home
