import React from 'react'
import { Helmet } from 'react-helmet'

import favicon from '../images/favicon.png'

interface SEOProps {
  title: string
}

export const SEO: React.FC<SEOProps> = (props) => {
  return (
    <Helmet title={props.title}>

      <meta
        name="description"
        content="Gestão de Projetos para você focar no seu negócio. Tenha total controle dos seus projetos em tempo real. Implantação do setor de projetos. Mais de 30 consultores focados em seu negócio."
      />

      <link
        rel="shortcut icon"
        type="image/x-icon"
        href={favicon}
      />

      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap"
      />

      <script
        async={true}
        type="text/javascript"
      >{`
        (function(w,d,u){
          var s=d.createElement('script');s.async=true;s.src=u+'?'+(Date.now()/60000|0);
          var h=d.getElementsByTagName('script')[0];h.parentNode.insertBefore(s,h);
        })(window,document,'https://cdn.bitrix24.com.br/b4841497/crm/site_button/loader_2_iljmvt.js');
      `}</script>

      {props.children}
    </Helmet>
  )
}