import React from 'react'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faQuoteRight } from '@fortawesome/free-solid-svg-icons'

import * as styles from '../styles/components/AboutUs.module.scss'
import adiel from '../images/adiel.jpg'
import adielAvatar from '../images/adielAvatar.jpg'

import { Button } from './Button'
import { Divider } from './Divider'

const services = [
  {
    text: `Robotic Process Automation (RPA)`,
    href: `https://valoremelhoria.com.br/rpa`
  },
  {
    text: `Sistema de Gestão Integrado (SGI)`,
    href: `https://valoremelhoria.com.br`
  },
  {
    text: `Controladoria`,
    href: `https://melhoriaderesultado.com.br/`
  },
]

export const AboutUs: React.FC = () => {
  return (
    <section
      id="quem-somos"
      className={clsx([`my-5`, styles.aboutUs])}
    >
      <div className="row">

        <div
          className={clsx([`d-none d-lg-block col-5`, styles.aboutUsBackground])}
          style={{ backgroundImage: `url(${adiel})` }}
        />

        <div className="d-none d-lg-block col-1" />

        <div className={clsx([`col`, `py-5`, styles.quoteContainer])}>
          <FontAwesomeIcon
            className={styles.quoteIcon}
            icon={faQuoteRight}
          />
          <h2 className={styles.quoteText}>
            Propomos soluções que geram valor com garantia e compromisso das melhores práticas de mercado. Prova disso são nossos inúmeros casos de sucesso.
          </h2>

          <div className="row mt-5 mt-lg-0">
            <div className="col-auto d-lg-none">
              <img
                src={adielAvatar}
                alt="Adiel Lima"
                className={styles.aboutUsAvatar}
              />
            </div>
            <div className="col">
              <p>
                <b>
                  <a href="https://br.linkedin.com/in/adiel-pavine-de-lima-b556b754" target="_blank" rel="noopener">Adiel Lima</a>
                </b>
              </p>
              <p>
                <span>CEO</span>
              </p>
            </div>
          </div>

          <div className="d-block d-lg-none d-xl-block">

            <Divider className={styles.spacer} />

            <p>
              A Valore Brasil é uma consultoria especializada com consultores altamente capacitados em ajudar sua empresa na implantação de boas práticas de mercado através de BPM, Qualidade, Controladoria e Tecnologia.
            </p>

            <div className="py-3">
              {services.map((service, index) => (
                <div key={index}>
                  <a
                    href={service.href}
                    target="_blank"
                    rel="noopener"
                  >
                    <FontAwesomeIcon
                      className={clsx([`mr-2`, styles.listIcon])}
                      icon={faCheckCircle}
                    />
                    {service.text}
                  </a>
                </div>
              ))}
            </div>

            <div>
              <a
                href="https://valoremelhoria.com.br/#block8421"
                target="_blank"
                rel="noopener"
              >
                <Button>
                  Conheça todos os serviços
                </Button>
              </a>
            </div>

          </div>

        </div>

      </div>
    </section>
  )
}